.teamSection {
    min-height: 100vh;
    font-size: small;
}
.teamSectionTitle {
    position: relative;
    font-family: var(--font-family-title);
    font-display: swap;
}

.teamGrid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
@media screen and (max-width: 900px) {
    .teamGrid {
        justify-content: center;
    }
}