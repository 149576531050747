.cookies {
    font-size: 0.75rem;
    color: grey !important;
}

.signUpButtonEN::after {
    content: 'Sign up today';
}

.signUpButtonNL::after {
    content: 'Schrijf u in';
}

.signUpButtonEN:hover::after {
    content: 'Smile with us';
}

.signUpButtonNL:hover::after {
    content: 'Lach met ons mee';
}

.appointmentButtonNL::after {
    content: 'Maak een afspraak';
}

.appointmentButtonEN::after {
    content: 'Make an appointment';
}

.appointmentButtonEN:hover::after {
    content: 'Your smile awaits!';
}

.appointmentButtonNL:hover::after {
    content: 'Uw glimlach wacht!';
}

.appointmentButtonNL,
.appointmentButtonEN {
    width: 13rem;
}

.signUpButtonEN,
.signUpButtonNL {
    border-radius: 0.5rem;
    font-weight: bold;
    width: 13rem;
    margin-right: 1rem;
  }

.signUpButtonWhite {
    font-size: small !important;
    background-color: var(--color-button-white) !important;
    border: none !important;
    margin-right: 0.5rem;
    margin-top: 0rem;
    color: var(--color-button-lightText) !important
}

.signUpButtonWhite:hover {
    background-color: var(--color-button-dark)  !important;
    color: var(--color-button-darkText) !important;
}

.signUpButtonOutline {
    font-size: small !important;
    background-color: var(--color-background) !important;
    border: 1px solid var(--color-white) !important;
    font-weight: bold;
    border-radius: 0.5rem;
}

.signUpButtonOutline:hover {
    background-color: var(--color-button-dark)  !important;
    color: var(--color-button-white) !important;
    border: 1px solid rgba(255,255,255,0) !important;
}

.modal-header {
    background-color: var(--color-white) !important;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    color: var(--color-darker) ;
}

.modal-title {
    font-size: small !important;
}

.modalImage {
    width: 10rem;
}

.form-control:focus {
    box-shadow: 0 0 0 .25rem rgba(175, 200, 203, .25) !important;
    border-color: rgba(175, 200, 203, 1) !important;
}

.form-control {
    color: var(--color-black) !important;
}

.modalButtonClose {
    font-size: small !important;
}

.modalButtonSignup {
    background-color: var(--color-button-dark)  !important;
    color: var(--color-button-darkText) !important;
    font-size:small !important;
    font-weight: bold !important;
}

.form-floating {
    label {
        color: var(--color-darker)  !important;
    }
}

.modal-title {
    font-size: 1rem !important;
}

.modal-header {
    background-color: var(--color-background) !important;
    color: var(--color-white) !important;
    height: 10rem !important;
    padding: 1rem;
}

.feedbackModal {
    width: 60%;
    font-size: small;

}

.feedbackContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}



.invalid-tooltip {
    background-color: var(--color-danger) !important;
}