.overlaySubtitle {
    font-weight: bold;
    padding-bottom: 1rem;
    padding-top: 1rem;
    color: var(--color-darker)
}
.overlayText {
    font-size: medium;
}

#overlayText {
    padding-left: 2rem;
    padding-right: 2rem;
}