.footerContainer {
    background-color: var(--color-background-secondary) !important;
}

.footerContent {
    font-size:small;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.footerLink {
    text-decoration: none;
    color: var(--color-link);
}
.footerLink:hover {
    color: lightgray;
}
.footerLinkLegal {
    font-size: smaller !important;
}

.footerInfo {
    width: 100%;
}

.footerLegal {
    font-size: smaller;
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: lightslategrey;
}


.footerSocials {
    display: flex;
    gap: 1rem;
}

.footerSectionTitle {
    font-size: smaller;
}

.footerContact {
    border: 1px solid #dee6e3;
    padding: 0.5rem;
    border-radius: 0.5rem;
}

@media screen and (max-width: 900px) {
    .footerContact {
        width: 100%;
    }
}