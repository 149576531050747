.teamMemberContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    border: 1px solid var(--color-white);
    background-color: var(--color-white);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.teamMemberImage {
    border-radius: 50%;
}
.teamMemberFunction {
    font-size: smaller;
    margin-bottom: 0;
}

.teamMemberName {
    font-weight: bold;
    margin-bottom: 0;
}
.teamMemberDescr {
    font-size: small;
}

@media screen and (max-width: 360px) {
    .teamMemberContainer {
        width: 100%;
        height: 11rem;
        border-radius: 31vw;
    }
    .teamMemberImage {
        width: 7rem;
        height: 7rem;
    }
    .teamMemberInfo {
        padding-right: 1rem;
        width: 60%;
    }
    .teamGrid .animated {
        width: 100% !important;
    }
}

@media screen and (min-width: 361px) and (max-width: 490px) {
    .teamMemberContainer {
        width: 100%;
        height: 8.5rem;
        border-radius: 31vw;
    }
    .teamMemberImage {
        width: 8.5rem;
        height: 8.5rem;
    }
    .teamMemberInfo {
        padding-right: 1rem;
        width: 60%;
    }
    .teamGrid .animated {
        width: 100% !important;
    }
}

@media screen and (min-width: 491px) and (max-width: 760px){
    .teamMemberContainer {
        width: 100%;
        height: 10rem;
        border-radius: 11rem;
    }
    .teamMemberImage {
        width: 10rem;
        height: 10rem;
    }
    .teamMemberInfo {
        padding-right: 1rem;
        width: 50%;
    }
    .teamGrid .animated {
        width: 100% !important;
    }
}

@media screen and (min-width: 761px) and (max-width: 900px){
    .teamMemberContainer {
        width: 30rem;
        height: 12rem;
        border-radius: 13rem;
    }
    .teamMemberImage {
        width: 12rem;
        height: 12rem;
    }
    .teamMemberInfo {
        padding-right: 1rem;
        width: 50%;
    }
}

@media screen and (min-width: 901px) and (max-width: 990px){
    .teamMemberContainer {
        width: 21rem;
        height: 10.5rem;
        border-radius: 11vw;
    }
    .teamMemberImage {
        width: 10.5rem;
        height: 10.5rem;
    }
    .teamMemberInfo {
        padding-right: 0.5rem;
        width: 50%;
    }
}
@media screen and (min-width: 991px) and (max-width: 1199px){
    .teamMemberContainer {
        width: 25rem;
        height: 10rem;
        border-radius: 11vw;
    }
    .teamMemberImage {
        width: 10rem;
        height: 10rem;
    }
    .teamMemberInfo {
        padding-right: 0.5rem;
        width: 50%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1300px){
    .teamMemberContainer {
        width: 30rem;
        height: 10rem;
        border-radius: 11rem;
    }
    .teamMemberImage {
        width: 10rem;
        height: 10rem;
    }
    .teamMemberInfo {
        padding-right: 1rem;
        width: 50%;
    }
}
@media screen and (min-width: 1301px){
    .teamMemberContainer {
        width: 22rem;
        height: 10rem;
        border-radius: 11rem;
    }
    .teamMemberImage {
        width: 10rem;
        height: 10rem;
    }
    .teamMemberInfo {
        padding-right: 1rem;
        width: 50%;
    }
}