.homeComplete {
  display: flex;
  flex-direction: column;
}
.homeBackground {
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: 35vw;
}

.homeContent {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: var(--color-white);
    font-weight: bold;
    font-family: var(--font-family-title);
    font-display: swap;
}

.titleContainer {
  max-width: 80%;
}

.homeSubtitle {
  font-size:small;
  font-weight: normal;
  padding-top: 1rem;
  padding-bottom: 1rem;
}


.aboutPractice {
    min-height: 100vh;
    background-color: var(--color-background-secondary) !important;
    width: 100%;
    color: var(--color-black);
}

#aboutPractice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5rem;
    width: 100%;
    min-height: 100vh;
}

@keyframes dash {
    from {
      stroke-dashoffset: 822;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  .titleContent {
    font-weight:300 !important;

    animation-name: slideInUp;
    animation-fill-mode: both;
    animation-duration: 1s;
  }
@keyframes slideInUp {
  0% {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  100% {
    transform: translateZ(0);
  }
}
  .homeSubtitle {
    word-spacing: 0.15rem;
    line-height: 1.15rem;
    animation-name: slideInUp;
    animation-fill-mode: both;
    animation-duration: 1s;
  }

  .animatedButton {
    animation: popUp 1.5s ease-in-out;
    opacity: 1;
  }

  .animatedButtonDelay {
    animation: popUp 2s ease-in-out;
    opacity: 1;
  }

  @keyframes popUp {
    0% { opacity: 0; transform: scale(0);}
    60% {opacity: 1; transform: scale(0);}
    90% { opacity: 1; transform: scale(1.05);}
    100% { opacity: 1; transform: scale(1);}
  }

  @keyframes fadeIn {
    0% { opacity: 0; transform: translateY(10%);}
    20% {opacity: 0; transform: translateY(10%);}
    100% { opacity: 1;transform: translateY(0%);}
  }

  .actionButtons {
    gap: 1rem;
    display: flex;
    flex-wrap: wrap;
  }

  