:root {
  --color-background: #899fc9;
  --color-darker:#828c8d;
  --color-highlight: #214966;
  --color-black: #202a2c;
  --color-white: #FFF;
  --color-link: #73b49e;
  --color-whiteoff: #faf7f3;
  --color-background-secondary: #faf7f3;
  --color-grey: #afafaf ;
  --color-buttonHover: #c2dfd5;
  --color-boxFocus: #a8c6d0;
  --font-family-sansSerif: "Barlow", Helvetica, sans-serif;
  --font-family-title: "Comfortaa", Helvetica, sans-serif;
  --color-button-dark: rgb(130, 140, 141);
  --color-button-darkText: #fff;
  --color-button-white: #fff;
  --color-button-light: #afc8cb;
  --color-button-lightText: #828c8d;
  --color-header-background: rgba(159, 171, 219, 0.5);
  --color-info-background: rgba(186, 212, 215, 0.85);
  --color-menu-background: rgba(147, 158, 201, 0.65);
  --color-header-text: #fff;
  --color-cards-background: #fff;
  --color-danger:#d56e8a;
}

.App {
  background-color: var(--color-background);
  width: 100vw;
  font-display: swap;
  font-family: var(--font-family-sansSerif);
  overflow-x: hidden;
}

.logo {
  height: 3rem;
  pointer-events: none;
}

.header {
  height: 5rem;
  display: flex;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  padding: 1rem;
  background: rgba(255, 255, 255,0.1);
  backdrop-filter: blur(6px);
  border-bottom: 1px solid #c0dadd;
}

.footer {
  display: flex;
  height: 5rem;
  background-color: var(--color-whiteoff);
}

