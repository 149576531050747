#contact {
    color: var(--color-white);
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.contactTitle {
    font-weight: bold !important;
    font-family: var(--font-family-title);
    font-display: swap;
    margin-top: 5rem !important;
}

.contactSubtitle {
    font-size: small !important;

}

.contactForm {
    margin-top: 1rem;
    margin-bottom: 5rem !important;
}

.contactInput {
    display: inline !important;
    background-color: var(--color-background) !important;
    border-radius: unset !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    width: 15rem !important;
}

.contactInput::placeholder {
    color: rgba(255,255,255, 0.5) !important;
}

.contactInputLong::placeholder {
    color: rgba(255,255,255, 0.5) !important;
}

.contactInputLong {
    display: inline !important;
    background-color: var(--color-background) !important;
    border-radius: unset !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    width: 35rem !important;
}

.contactInput:focus {
    color: var(--color-black) !important;
    border-color: var(--color-white) !important;
    outline: 0;
    box-shadow: none !important;
}

.contactInputLong:focus {
    color: var(--color-black) !important;
    border-color: var(--color-white) !important;
    outline: 0;
    box-shadow: none !important;
}

.contactSubmitButtonEN,
.contactSubmitButtonNL {
    color: var(--color-white) !important;
    border: 1px solid var(--color-white) !important;
    border-radius: 0.5rem !important;
    margin-top: 1rem;
    font-size: small !important;
}

.contactSubmitButtonEN:hover,
.contactSubmitButtonNL:hover {
    background-color: var(--color-darker) !important;
    border: 1px solid var(--color-darker)  !important;
}

.contactSubmitButtonEN::after {
    content: "Let's get in touch";
}

.contactSubmitButtonEN:hover::after {
    content: 'We love hearing from you';
}

.contactSubmitButtonNL::after {
    content: "Neem contact op";
}

.contactSubmitButtonNL:hover::after {
    content: 'Wij horen graag van u';
}

.contactFormLabel {
    margin-left: 0.5rem;
}

.contactIcons {
    display: inline;
    color: var(--color-white);
    padding: 0.5rem;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactIcons:hover {
    background-color: var(--color-darker) ;
    border-radius: 50%;
}

.contactIcons:hover .contactIcon {
    scale: 1.2;
}

.contactIconsFooter{
    display: inline;
    padding: 0.5rem;
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contactIconsFooter:hover{
    border-radius: 50%;
}
.contactIconsFooter:hover .contactFooterIcon {
    scale: 1.2;
}

.contactOther {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.contactInputLong,
.contactInput {
    width: 95% !important;
}


.contactSubmit {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

#contactForm {
    position: relative;
}

.contactGroup {
    position: relative;
    margin-bottom: 1.5rem !important;
}