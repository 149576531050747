.cookiesButton {
    padding: 0 !important;
    font-size: smaller !important;
    color: var(--color-link) !important;
}

.cookiesButton:hover {
    color: lightgray !important;
}

.cookiesModal {
    font-size: small !important;
}