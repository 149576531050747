#treatments {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6rem;
  padding-bottom: 5rem;
  min-height: 100vh;
}
.containerTreatments{
  max-width: 100%;
}
  
.gridTreatments{
  display: grid;
  gap: 0.5rem;
  margin: 0 auto;
  max-width: 100%;
}

.boxTreatment {
  flex-wrap: nowrap;
  justify-content:center;
  align-items:center;
  border: none;
}
.boxTreatmentTitle {
  display:flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.boxImage {
  width: 2rem;
}

@media screen and (max-width: 1200px) {
  .boxTreatmentTitle {
    flex-direction: row;
    justify-content:center;
    align-items:center;
    color: var(--color-black);
  }
}

.boxTreatment:focus-visible {
  outline: 2px outset var(--color-boxFocus);
}

.boxText {
  width:100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.boxArrow {
  animation: out 0.5s;
  opacity: 0;
  transform-style: preserve-3d;
  margin-left: .75em;
  display: inline-block;
  transition: all .2s ease-in-out;
  font-size: small;
}
  
.boxTreatment {
    width: 100%;
    position:relative;
    cursor:pointer;
    background-color: var(--color-cards-background);
    border-radius: 0.25rem;
    display:flex;
    justify-content: space-between;
    align-items:center;
    flex-direction:row;
    transition: transform, box-shadow 0.1s linear;
    padding-top:2rem;
    padding-bottom:2rem;
    padding-left:1rem;
    padding-right:1rem;
}

.boxTreatment:hover {
  box-shadow: 0 32px 48px #08080805, 0 16px 20px #08080808, 0 6px 8px #0808080a, 0 1px 3px #0808080f;
  color: var(--color-darker) ;
}

.boxTreatment:hover .boxArrow {
  transition: all .2s ease-in-out;
  animation: in 0.5s;
  opacity: 1;
}

.box1 {
  grid-area: box1;
}
.box2{
  grid-area: box2;
}
.box3{
  grid-area: box3;
}
.box4{
  grid-area: box4;
}
.box5{
  grid-area: box5;
}
.box6{
  grid-area: box6;
}
.box7{
  grid-area: box7;
}
.box8{
  grid-area: box8;
}
.box9{
  grid-area: box9;
}
.box10{
  grid-area: box10;
}
.box11{
  grid-area: box11;
}
.box12{
  grid-area: box12;
}

@media screen and (max-width: 480px) {
  #treatments {
      align-items:center;
  }
  .specsTitleContainer {
      width: 65%;
  }
  .gridTreatments {
      grid-template-columns: repeat(1, 1fr);
      grid-template-areas:
      'box1' 
      'box2'
      'box3'
      'box4'
      'box5'
      'box6'
      'box7'
      'box8'
      'box9'
      'box10'
      'box11'
      'box12';
  }
  .boxArrow {
  opacity: 1 !important;
  transform: translate(0,0,0);
  animation: none !important;
  }
}


@media screen and (min-width: 481px) and (max-width: 950px) {
  .gridTreatments {
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
      'box1 box1' 
      'box2 box3'
      'box4 box5'
      'box6 box6'
      'box7 box8'
      'box9 box10'
      'box11 box11'
      'box12 box12';
  }
  .boxArrow {
  opacity: 1 !important;
  transform: translate(0,0,0);
  animation: none !important;
  }
}

@media screen and (min-width: 951px) {
  .gridTreatments {
      grid-template-columns: repeat(4, 1fr);
      grid-template-areas:
      'box1 box1 box2 box3'
      'box4 box5 box5 box6'
      'box7 box8 box9 box10'
      'box11 box11 box12 box12';
  }
}


@keyframes in {
  0%   { transform: translate(-10px, 10px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); }
  100% { transform: translate(0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);}
}

@keyframes out {
  0%   { transform: translate(0, 0) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); }
  100% { transform: translate(10px, -10px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);}
}

.overlay {
  background-color: var(--color-white);
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  box-shadow: 0 7px 16px 0 rgba(0, 0, 0, .15);
  display: none;
  opacity: 0;
  z-index: 2000;
  width: 0;
  overscroll-behavior: contain;
  transition: right 0.5s ease-in-out allow-discrete, display 1s ease allow-discrete, box-shadow -3s ease-in-out;
  right: -100%;
}

@media screen and (max-width: 480px) {
  .overlay.open {
      width: 100%;
  }
}
@media screen and (min-width: 481px) and (max-width: 600px) {
  .overlay.open {
      width: 100%;
  }
}
@media screen and (min-width: 601px) and (max-width: 990px) {
  .overlay.open {
      width: 80%;
  }
}
@media screen and (min-width: 991px) {
  .overlay.open {
      width: 40%;
  }
}

.overlay.open {
  overscroll-behavior: contain;
  opacity: 1;
  display: block !important;
  transition: right 0.5s ease-in-out allow-discrete, display 1s ease allow-discrete, box-shadow -3s ease-in-out;
  box-shadow: 0px 0px 0px 4000px rgba(0, 0, 0, 0.3) ;
  right: 0;
  overflow-y: scroll;
}

.overlayHeader {
  height: 5rem;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: var(--color-background);
  color: var(--color-white);
  font-weight: bold;
  font-family: var(--font-family-title);
  font-display: swap;
  box-shadow:0px 0px 5px 0px rgba(0, 0, 0, .15);
  position: sticky;
  top: 0;
}

.overlayClose {
  width: 1rem;
  height: 1rem;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    height: 2px;
    width: 1rem;
    background-color: var(--color-white);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    transition: ease-out .3s all;
  }
  &:after {
    content: "";
    position: absolute;
    height: 2px;
    width: 1rem;
    background-color: var(--color-white);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: ease-out .3s all;
  }
  &:hover, &:focus {
    &:before {
      transform: translate(-50%, -50%) rotate(135deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(225deg);
    }
  } 
}

#overlayText {
    padding: 1rem;
}

.specsTitle {
    margin-bottom: 3rem !important;
    color: var(--color-whiteoff) !important;
    font-family: var(--font-family-title);
    font-display: swap;
}
