.aboutSection {
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .imageSwiper {
    width: 110vw;
    left: -5vw;
  }

  .swiperImage {
    width: 40vw;
  }

  @media screen and (max-width: 480px) {
    .swiperImage {
      width: 80vw;
    }
  }
  @media screen and (min-width: 481px) and (max-width: 900px) {
    .swiperImage {
      width: 60vw;
    }
  }

  .practiceAbout {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-top: 0 !important;
    position: relative;
  }
  .aboutText {
    font-size: small;
  }
  .aboutTitle {
    position: relative;
    font-family: var(--font-family-title);
    font-display: swap;
  }

.practiceDetails {
  height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
}


  .teamArrow {
    position: absolute;
    top: -60px;
    left: 20px;
    width: 4rem;
  }

.slideAnimation {
  animation: enter-animation .8s 1;
}

@keyframes enter-animation {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .slideAnimation {
    animation-name: enter-animation;
    animation-duration: 0.8s;
  }
}