.emergencyModal {
    font-size: small !important;
}
.emergencyHeader {
    min-height: 5rem !important;
    color: var(--color-header-text) !important;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    cursor: pointer;
    font-size: 1em !important;
}

.emergencyHeader:hover{
    background-color: rgba(255, 255, 255, 0.2) !important;
    border-bottom: 1px solid var(--color-header-text) !important;
    color: var(--color-header-text);
}

.callButtonEmergency {
    background-color: var(--color-background);
    width: 100% !important;
    padding: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-decoration: none !important;
    color: var(--color-white) !important;
    border-radius: 0.5rem;
}

.emergencySubtitle {
    padding-top: 1rem;
    margin-top: 1rem;
}
