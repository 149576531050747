.contactSection {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    gap: 1rem;
}

.callButton {
    text-decoration: none !important;
    color: var(--color-highlight);
    border: 1px solid var(--color-highlight);
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.bi {
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
}