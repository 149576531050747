.loadingFeedback {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: small;
}

.loader {
    width: 120px;
    height: 22px;
    border-radius: 20px;
    color: var(--color-background);
    border: 2px solid;
    position: relative;
  }
  .loader::before {
    content: "";
    position: absolute;
    margin: 2px;
    inset: 0 100% 0 0;
    border-radius: inherit;
    background: currentColor;
    animation: l6 2s infinite;
  }
  @keyframes l6 {
      100% {inset:0}
  }
