#information {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--color-background-secondary);
}

.infoDetails {
    gap: 2rem;
    justify-content: space-between;
    width: 100%;
  }
  
  .infoDetailsContainer {
    background-color: var(--color-white);
    border-radius: 0.5rem;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    font-size: small;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  
  .infoDetailsTitle {
    font-weight: bold;
    font-size: smaller;
  }

  .secondaryContainer {
    display: flex;
    flex-wrap: wrap;
  }

  .infoDetailsSecondary {
    background-color: var(--color-cards-background);
    color: var(--color-darker) ;
    width: 20rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
  }

  .infoImage {
    width: 100%;
  }

  .secondaryExplanation {
    font-size: smaller;
    width: 12rem;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .secondaryActionButton {
    display: flex;
    justify-content: center;
    align-items: end;
  }

  .secondaryActionButtonDark {
    font-size: small !important;
    background-color: var(--color-background) !important;
    border: none !important;
    margin-right: 0.5rem;
    margin-top: 0rem;
    color: var(--color-white) !important
}

.secondaryActionButtonDark:hover {
    background-color: var(--color-darker)  !important;
    color: var(--color-buttonHover) !important;
}

  .contactLink {
    text-decoration: none !important;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

@media  screen and (max-width: 480px) {
  #information {
    min-height: 100vh;
    height: max-content;
  }
  .infoDetailsSecondary {
    width: 90%;
  }
  .secondaryExplanation {
    width: 95%;
    padding-left: 0;
    padding-right: 0;
  }
  .secondaryContainer {
    justify-content: center;
  }
}