.navbar {
  background-color: var(--color-header-background);
  backdrop-filter: blur(6px);
  font-size: 1rem;
  background-color: none;
  display: flex;
  width: 100%;
  justify-content: space-between;
  animation: slideIn 0.5s ease-in-out;
  padding: 0 !important;
  position: fixed !important;
}

.brandImage {
  max-height: 3rem;
}

.navigationLink {
  color: var(--color-header-text);
  height: 100%;
  text-decoration: none !important;
}

.navigationSubLink {
  color: var(--color-header-text);
}

.navbar-fixed-bottom .navbar-collapse, .navbar-fixed-top .navbar-collapse {
  max-height: none;
}

#basic-navbar-nav {
  transition: 0.25s ease-in;
}

.navbar-collapse.show {
  background-color: var(--color-menu-background);
}

@keyframes slideIn {
  0%{opacity: 0; };
  90%{opacity: 0;}
  100%{opacity: 1;}
}

#basic-navbar-nav {
  width: 100%;
  min-height: 5rem !important;
}

.navigation {
  min-height: 5rem !important;
}

#basic-navbar-nav {
  height: 100%;
}

.navigation {
  height: 100%;
}

.linkNav {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 5rem !important;
  color: var(--color-header-text);
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  cursor: pointer;
}

.linkNav:hover {
  background-color: rgba(255, 255, 255,0.2);
  border-bottom:1px solid var(--color-header-text);
  color: var(--color-white);
}

#languageEN, #languageNL {
  color: var(--color-darker)  !important;
}

.callButtonHeader {
  display: flex;
}

.headerCallSection {
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  font-size: small;
}

.headerCallPhone {
  font-weight: bold;
  color: var(--color-header-text);
}

.brandContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.practiceName {
  font-size: medium !important;
  font-weight: bold;
  letter-spacing: 0.2rem;
}

.practiceDescription {
  font-size: small !important;
}

.logoName {
  display: flex;
  flex-direction: column;
}
.logoName:hover {
    color: var(--color-header-text) !important;
}
.navbar-brand:hover {
  color: var(--color-header-text) !important;
}
.navLanguage {
  color: var(--color-header-text);
  cursor: pointer;
}

.navLanguage:hover {
  color: var(--color-header-text);
  background-color: rgba(255, 255, 255,0.2);
  border-bottom:1px solid var(--color-header-text);
}
